











































































































import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { email } from 'vee-validate/dist/rules';
import { SfInput, SfButton, SfModal } from '@storefront-ui/vue';
import { defineComponent, ref, useContext } from '@nuxtjs/composition-api';
import userGetters from '~/modules/customer/getters/userGetters';
import { useUser } from '~/modules/customer/composables/useUser';
import { useUiNotification } from '~/composables';

import type { SubmitEventPayload } from '~/modules/customer/types/form';
import type { ProfileUpdateFormFields } from '~/modules/customer/pages/MyAccount/MyProfile/types';

extend('email', {
  ...email,
  message: 'The email field must be a valid email',
});

export default defineComponent({
  components: {
    SfInput,
    SfButton,
    SfModal,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['submit'],
  setup(_props, { emit }) {
    const { app: { i18n } } = useContext();
    const { user } = useUser();
    const currentPassword = ref('');
    const requirePassword = ref(false);
    const getInitialForm = () : ProfileUpdateFormFields => ({
      firstname: userGetters.getFirstName(user.value),
      lastname: userGetters.getLastName(user.value),
      email: userGetters.getEmailAddress(user.value),
    });
    const { send: sendNotification } = useUiNotification();

    const form = ref(getInitialForm());

    const submitForm = (resetValidationFn: () => void) => () => {
      const onComplete = () => {
        form.value = getInitialForm();
        requirePassword.value = false;
        currentPassword.value = '';
        sendNotification({
          id: Symbol('user_updated'),
          message: i18n.t('The user account data was successfully updated!') as string,
          type: 'success',
          icon: 'check',
          persist: false,
          title: 'User Account',
        });
        resetValidationFn();
      };

      const onError = (message: string) => {
        sendNotification({
          id: Symbol('user_updated'),
          message: i18n.t(message) as string,
          type: 'danger',
          icon: 'cross',
          persist: false,
          title: 'User Account',
        });
      };

      const isEmailChanged = userGetters.getEmailAddress(user.value) !== form.value.email;

      if (isEmailChanged && !requirePassword.value) {
        requirePassword.value = true;
      } else {
        if (currentPassword.value) {
          form.value.password = currentPassword.value;
        }

        const eventPayload : SubmitEventPayload<ProfileUpdateFormFields> = { form: form.value, onComplete, onError };

        emit('submit', eventPayload);
      }
    };

    return {
      requirePassword,
      currentPassword,
      form,
      submitForm,
    };
  },
});
